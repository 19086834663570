import * as dialogapi from "dompack/api/dialog";
import * as finmath from "@mod-system/js/util/finmath";
import * as whintegration from "@mod-system/js/wh/integration";

import * as dompack from "dompack";

import $ from "jquery";

let totalIncrease;
let productSelects;
let productImages;
let currentPreview;

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains("page-product")) return;

  productSelects = dompack.qSA(".webshop-product__options");

  productImages = $(".product__images__grid").find(".productimage-color");
  currentPreview = $(".product__images__preview");

  productSelects.forEach((select) => {
    let productOptionsSelects = select.querySelectorAll(
      ".webshop-product__option"
    );

    productOptionsSelects.forEach((productOptionsSelect) => {
      let optionSelect = productOptionsSelect.querySelector(
        ".webshop-product__optionselect"
      );
      optionSelect.addEventListener("change", () => {
        setTimeout(handleSelects(optionSelect), 400);
      });
    });
  });

  dompack.qS("webshop-product").addEventListener("change", (evt) => {
    handleStockError();
  });

  // setTimeout(handleSelects, 700);
  setTimeout(handleInitialStock, 700);
  setTimeout(handleStockError, 700);

  addEventListener("webshop:productadded", (evt) => {
    evt.preventDefault();
    window.scrollTo(0, 0);
    dompack.qS(".cart__popup__overlay").classList.toggle("show");
    dompack.qS("body").classList.toggle("popup-shown");
  });

  dompack.qS("#continueshopping-popup").addEventListener("click", (evt) => {
    dompack.qS(".cart__popup__overlay").classList.toggle("show");
    dompack.qS("body").classList.toggle("popup-shown");
  });

  dompack.qS("#close-popup").addEventListener("click", (evt) => {
    dompack.qS(".cart__popup__overlay").classList.toggle("show");
    dompack.qS("body").classList.toggle("popup-shown");
  });

  // dompack.qS(".cart__popup__overlay").addEventListener("click", (evt) => {
  //   dompack.qS(".cart__popup__overlay").classList.toggle("show");
  // });
});

// FIXME: check if stock meets minimum for options stock and set SoldOut if no stock is present
function handleStockError() {
  // if ( dompack.qS("webshop-product").getAttribute("data-webshop-in-stock") <= 0 )
  // dompack.qS("#deliverytime").innerHTML = dompack.qS("webshop-product").dataset.outofstockmessage;
}

function handleInitialStock() {
  if (whintegration.config.obj.infinitestock)
    dompack.qS("#deliverytime").innerHTML =
      dompack.qS("webshop-product").dataset.delivery;
  else if (parseInt(dompack.qS("webshop-product").dataset.webshopInStock) > 0) {
    dompack.qS("#deliverytime").innerHTML =
      dompack.qS("webshop-product").dataset.delivery;
  } else {
    dompack.qS("#deliverytime").innerHTML =
      dompack.qS("webshop-product").dataset.outofstockmessage;
  }
}

function handleSelects(select) {
  let selectedOption = select.querySelector(`option[value='${select.value}']`);
  totalIncrease = parseFloat($(selectedOption).attr("data-extraprice"));

  let listPrice = dompack.qS(".p-product__price--old");

  if (listPrice) {
    let formattedBaseListPrice = listPrice.dataset.baseListPrice
      .replace(/,(?!.*,)/, "#")
      .replace(/[,.]/g, "")
      .replace("#", ".");

    let oldListPrice = parseFloat(formattedBaseListPrice);
    let newListPrice = oldListPrice + totalIncrease;

    listPrice.innerHTML = `€ ${formatCurrency(newListPrice)}`;
  }

  if (whintegration.config.obj.infinitestock) {
    dompack.qS("#deliverytime").innerHTML =
      dompack.qS("webshop-product").dataset.delivery;
    return;
  }

  if (select == undefined) {
    let productSelect = dompack.qS(".webshop-product__options");
    if (productSelect == undefined) {
      if (whintegration.config.obj.availability != undefined)
        dompack.qS("#deliverytime").innerHTML =
          whintegration.config.obj.availability.wrd_title;
      return;
    }

    select = productSelect.querySelector(".webshop-product__optionselect");
  }

  let productdelivery = dompack.qS("webshop-product").dataset.delivery;
  let optiondelivery = selectedOption.dataset.delivery;
  let delivery = "";

  if (
    dompack.qS("webshop-product").getAttribute("data-webshop-in-stock") <= 0
  ) {
    delivery = dompack.qS("webshop-product").dataset.outofstockmessage;
  } else {
    productdelivery = productdelivery != "" ? productdelivery : "";
    optiondelivery = optiondelivery != "" ? optiondelivery : "";

    delivery = productdelivery != "" ? productdelivery : optiondelivery;
  }

  dompack.qS("#deliverytime").innerHTML = delivery;

  productImages.each(function () {
    if ($(this).attr("alt") == $(selectedOption).data("title")) {
      let newFullImg = $(this).parent().attr("href");
      let newPrevImg = $(this).data("previewlink");

      let fullImgDst = $(currentPreview).find(".fullimagelink")[0];
      let prevImgDst = $(currentPreview).find(".productimage-main")[0];

      $(fullImgDst).attr("href", newFullImg);
      $(prevImgDst).attr("src", newPrevImg);
    }
  });
  setTimeout(handleStockError, 500);
}

function formatCurrency(value) {
  let parts = value.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}
