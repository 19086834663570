// Auto-generated RPC interface from /opt/whdata/installedmodules/diveworld.20241212T082451.143Z/webdesigns/diveworldshop/shared/js/services.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("diveworld:rpc");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/diveworld.20241212T082451.143Z/webdesigns/diveworldshop/shared/lib/rpc.whlib'

exports.filterProducts = exports.FilterProducts = /*INTEGER ARRAY*/function(/*RECORD ARRAY*/ allproducts, /*RECORD*/ filters)
{
return request.invoke.apply(request,["FilterProducts"].concat(Array.prototype.slice.call(arguments)));
}

exports.sortProducts = exports.SortProducts = /*INTEGER ARRAY*/function(/*RECORD ARRAY*/ allproducts, /*STRING*/ type)
{
return request.invoke.apply(request,["SortProducts"].concat(Array.prototype.slice.call(arguments)));
}

exports.getProductImageForOption = exports.GetProductImageForOption = /*STRING*/function(/*INTEGER*/ id, /*INTEGER*/ optionid)
{
return request.invoke.apply(request,["GetProductImageForOption"].concat(Array.prototype.slice.call(arguments)));
}

exports.searchProductsByName = exports.SearchProductsByName = /*RECORD ARRAY*/function(/*STRING*/ title)
{
return request.invoke.apply(request,["SearchProductsByName"].concat(Array.prototype.slice.call(arguments)));
}
